import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Alert component is used to display important information or alerts to users. It is commonly used for information, warnings, errors, success messages, and notifications.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/guideline-alert-anatomy.svg",
      "alt": "anatomy alert"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Heading`}</strong>{`: The Alert type refers to the type of alert being displayed, such as success, warning, error or info. It can be indicated by a specific color or icon, depending on the design.`}</li>
      <li parentName="ol"><strong parentName="li">{`Alert Type`}</strong>{`: The icon is an optional element that can be included in an Alert component to reinforce the type of the alert being displayed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: The container is the outermost element that holds the alert component. It can be a div or any other appropriate HTML element.`}</li>
      <li parentName="ol"><strong parentName="li">{`Message`}</strong>{`: The message is the text content of the Alert component that conveys the important information to the user. It should be clear, concise and easy to read.`}</li>
      <li parentName="ol"><strong parentName="li">{`Close button`}</strong>{`: The close button is an optional element that allows the user to dismiss the alert once they have read it. It can be indicated by an X icon or any other appropriate symbol.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Alert component is used to provide users with important information or alerts. It is commonly used in web applications, online forms, and other contexts where users need to be notified of changes or errors.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        Error messages: When a user encounters an error or a problem while using the application or website, an Alert
        component can be used to provide information about the issue and offer a solution.
      </li>
      <li>
        Confirmation messages: When a user performs an action that has significant consequences, such as deleting data
        or making a payment, an Alert component can be used to confirm the action and provide further instructions.
      </li>
      <li>
        Notifications: When there is important information that the user needs to be aware of, such as changes to
        account settings or new features, an Alert component can be used to notify them.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        Informational messages: If the message is not urgent or important and does not require immediate attention, an
        Alert component may not be necessary. In such cases, a less intrusive method such as a tooltip or a banner might
        be more appropriate.
      </li>
      <li>
        Warnings: If the message is a warning that does not require immediate attention, a different type of component,
        such as a modal or a popover, might be more appropriate.
      </li>
      <li>
        Success messages: While success messages can be displayed using an Alert component, they might be better suited
        to a different type of component such as a banner or a status indicator.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Accessibility is an important consideration when designing and implementing the Alert component to ensure that it can be used effectively by all users. Here are some accessibility consideration for Alert:`}</p>
    <ul>
      <li parentName="ul">{`Use clear and concise text: The text used in the Alert component should be easy to read and understand, with a font size and color contrast that meet accessibility standards.`}</li>
      <li parentName="ul">{`Ensure proper color contrast: Make sure the color of the Alert component contrasts sufficiently with the background color to make it visible to users with color blindness or low vision. WCAG recommends a minimum contrast ratio of 4.5:1 between text and background.`}</li>
      <li parentName="ul">{`Use ARIA attributes: Use ARIA attributes to label the Alert component as an alert and provide additional context for screen readers.`}</li>
      <li parentName="ul">{`Include a focus indicator: Ensure that the Alert component has a focus indicator that is visible and easily identifiable by keyboard users. This will help users who navigate the website or application using a keyboard.`}</li>
      <li parentName="ul">{`Allow enough time for users to read the message: Users may require more time to read the Alert message due to a disability. Ensure that the Alert remains visible for an appropriate amount of time to allow users to read and process the information.`}</li>
      <li parentName="ul">{`Use descriptive icons: If icons are used in the Alert component, make sure they are descriptive and not reliant on color alone to convey meaning. This will help users with visual impairments or color blindness to understand the Alert’s type.`}</li>
      <li parentName="ul">{`Test with assistive technology: Test the Alert component with assistive technology such as screen readers and keyboard-only navigation to ensure that it is accessible to users with disabilities.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use clear and concise language for the alert message." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/guideline-alert-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use unclear or misleading language for the alert message." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/guideline-alert-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Alert:`}</p>
    <ul>
      <li parentName="ul">{`Use clear and direct language: The language used in the Alert should be easy to understand and free of technical jargon or industry-specific terms that may be confusing to the user.`}</li>
      <li parentName="ul">{`Keep it short: The message should be brief and to the point, conveying the essential information in a concise manner.`}</li>
      <li parentName="ul">{`Provide clear instructions: If the Alert requires the user to take action, provide clear instructions on what they need to do next. Use actionable language and provide a link or button to take the required action.`}</li>
      <li parentName="ul">{`Use a conversational tone: Using a conversational tone in the Alert message can make it feel more personal and less intimidating to the user.`}</li>
      <li parentName="ul">{`Be specific and relevant: The Alert should be specific to the action or event that triggered it, and the information provided should be relevant to the user.`}</li>
      <li parentName="ul">{`Avoid unnecessary information: Do not include information that is not relevant to the Alert, as it can be distracting and confusing to the user.`}</li>
      <li parentName="ul">{`Use a consistent tone: The tone of the Alert should be consistent with the tone used throughout the application or website. If the Alert is urgent or critical, use appropriate language and tone to convey this.`}</li>
      <li parentName="ul">{`Test the content: Test the Alert message with users to ensure that it is clear, understandable, and effective in communicating the required information.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="The message should be brief and to the point, conveying the essential information in a concise manner." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/guideline-alert-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="The message is long and didn't point out the message clearly, conveying the non-essential information." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/guideline-alert-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      